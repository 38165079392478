<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-clipboard"></i> Dispatch Importer</b-card-title>
			<b-card-sub-title>Imports dispatch data in bulk</b-card-sub-title>
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

			<div fluid class="px-2 mt-4">
				<b-form @submit.stop.prevent="saveOnDatabase" novalidate>
					<div v-show="!importOngoing && !importDone">
						<!-- Company Form -->
						<b-card title="Upload Form">
							<b-row class="mt-2 mb-4 ml-1" no-gutters>
								<b-col sm="10" md="8" lg="6" class="mr-4">
									<b-form-group label="Dispatch Form"
										description="Please select a valid json or csv file for dispatch import form format.">
										<b-form-file v-model="file" ref="fileinput" @change="onUploadForm($event)"
											:state="Boolean(file)"
											placeholder="Choose a JSON or CSV file"></b-form-file>
									</b-form-group>
									<div>
										Download JSON template
										<a :href="jsonTemplateUrl" download="DispatchImporter.json">
											here
										</a>
										and CSV template
										<a :href="csvTemplateUrl" download="DispatchImporter.csv">
											here.
										</a>
									</div>
								</b-col>
								<b-col sm="1">
									<b-button variant="primary" class="reset-button"
										@click="startAnotherImport()">Reset</b-button>
								</b-col>
							</b-row>
						</b-card>

						<!-- Content Preview -->
						<b-card v-if="!importOngoing && jsonData !== null" title="Content Preview"
							sub-title="Below is the overview of the content of the dispatch import form you have selected">
							<br />
							<json-viewer :value="jsonData" />
							<br />
							<b-button variant="primary" @click="onImportData()">Import</b-button>
						</b-card>
					</div>

					<div v-show="importOngoing || importDone">
						<b-row class="my-12">
							<b-col sm="12">
								<b-card title="Import Status" sub-title>
									<div v-if="importErrors.length > 0">
										<p class="card-text">{{ importResultLog }}</p>
										<ul>
											<li v-for="(item, index) in importErrors" :key="index">
												{{ item }}
											</li>
										</ul>
									</div>

									<div v-else>
										<p class="card-text my-4">
											{{ importStatusDisplay }}
										</p>
									</div>

									<span v-show="!importOngoing">
										<b-button variant="primary" @click="startAnotherImport()">
											Start Another Import
										</b-button>
									</span>
								</b-card>
							</b-col>
						</b-row>
					</div>
				</b-form>
			</div>
		</b-card>
	</div>
</template>

<script>
// Utils
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';
import { ImportUtil } from '@/utils/importUtil';
import { UserUtil } from '@/utils/userutil';

// API & DAO
import dataImporterApi from '@/api/dataImporterApi';
import assetTypeDAO from '@/database/assetTypes';

// Others
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
let Papa = require('papaparse');
import _ from 'lodash';


export default {
	name: 'dispatch-importer',
	components: { Loading, },
	data() {
		return {
			file: null,
			jsonData: null,

			importOngoing: false,
			importDone: false,
			hasError: false,
			importResultLog: '',
			importErrors: [],

			dispatches: [],

			companiesObj: {},
			storageLocationsObj: {},
			connectionsObj: {},
			transportationsObj: {},
			assetTypesObj: {},
			usersObj: {},
			clientAccountsObj: {},

			jsonTemplateUrl: '../../assets/files/DispatchImporter.json',
			csvTemplateUrl: '../../assets/files/DispatchImporter.csv',

			currUserId: this.$store.getters.loggedUser.id,
			isLoading: false,
		};
	},
	computed: {
		importStatusDisplay() {
			let statusDisplay = '';

			if (this.importOngoing) {
				statusDisplay = 'Import In-Progress.';
			} else if (this.hasError) {
				statusDisplay = this.importResultLog;
			} else {
				statusDisplay = 'Import Successful! \n' + this.importResultLog;
			}
			return statusDisplay;
		},
	},
	mounted() {		
		setTimeout(() => {
			try {
				// Filter Access
				if (this.$store.getters.isViewer || this.$store.getters.isScanner || this.$store.getters.isRetailer) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// Show loader
				this.isLoading = true;

				// Load initial data
				this.companiesObj = ImportUtil.getCollectionByKey({ ...this.$store.getters.companies, ...this.$store.getters.connectedCompanies }, 'name');
				this.storageLocationsObj = ImportUtil.getCollectionByKey({ ...this.$store.getters.storageLocations, ...this.$store.getters.connectedStorageLocations }, 'name');
				this.usersObj = ImportUtil.getCollectionByKey({ ...this.$store.getters.users, ...this.$store.getters.connectedUsers }, 'id');
				this.connectionsObj = ImportUtil.getConnectionsObj({ ...this.$store.getters.connections });
				this.transportationsObj = ImportUtil.getCollectionByKey({ ...this.$store.getters.transportations }, 'plateNo');
				this.assetTypesObj = ImportUtil.getCollectionByKey({ ...this.$store.getters.assetTypes }, 'name');
				this.clientAccountsObj = ImportUtil.getCollectionByKey({ ...this.$store.getters.clientAccounts }, 'accountNo');

			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}, config.timeout);
	},
	methods: {
		startAnotherImport() {
			this.importDone = false;
			this.importOngoing = false;

			// reset error status
			this.resetErrorStatus();

			// reset form fields
			this.$refs.fileinput.reset();
			this.file = null;
			this.jsonData = null;
			this.dispatches = [];
		},
		resetErrorStatus() {
			this.hasError = false;
			this.importResultLog = '';
			this.importErrors = [];
		},

		onUploadForm(ev) {
			const file = ev.target.files[0];

			if (this.validateUploadForm(file)) {
				const reader = new FileReader();
				reader.onload = (e) => {
					let extension = file.name.split('.').pop().toLowerCase();
					if (extension === 'csv') {
						const { data } = Papa.parse(e.target.result, {
							header: true,
							skipEmptyLines: true,
							dynamicTyping: true,
							encoding: "UTF-8",
						});
						this.jsonData = this.formatJsonData(data, extension);
					} else {
						this.jsonData = JSON.parse(e.target.result);
						this.jsonData = this.formatJsonData(this.jsonData.dispatches, extension);
					}
				};

				reader.readAsText(file);
			}
		},
		validateUploadForm(file) {
			let isValid = true;
			const fileTypes = ['csv', 'json'];
			const extension = file.name.split('.').pop().toLowerCase();
			const isAllowed = fileTypes.indexOf(extension) > -1;

			if (!file) {
				this.$toaster.warning('Please select a valid dispatch form to proceed.');
				isValid = false;
				this.jsonData = null;
			} else if (!isAllowed) {
				this.$toaster.error('Invalid File Type: Please import a valid dispatch form in JSON or CSV format.');
				isValid = false;
				this.jsonData = null;
			}

			return isValid;
		},
		formatJsonData(params, extension) {
			let currJson = {};
			let currIndex = -1;

			let results = [];
			params.forEach((param) => {
				param = ImportUtil.trimWhiteSpaces(param);

				if (!this.isSameDispatch(param, currJson, extension)) {
					currIndex++;
					currJson = param;
					results.push(this.getDispatchObj(param, extension, currIndex));
				}

				if (results[currIndex] && extension === 'csv') {
					let actual = param['actualQuantity'];
					let expected = param['expectedQuantity'];

					results[currIndex].assets.push({
						assetType: param['assetType'],
						expectedQuantity: expected ? parseInt(expected) : 0,
						actualQuantity: actual ? parseInt(actual) : 0
					});
				}
			});

			return { "dispatches": results };
		},
		getDispatchObj(param, extension, currIndex) {
			let currTimeStamp = DateUtil.getCurrentTimestamp() + currIndex;
			if (extension === 'csv') {
				return {
					dispatchId: 'DS' + currTimeStamp,
					notes: param.notes,
					dateDeployed: param.dateDeployed,
					deployedBy: param.deployedBy,
					dateReceived: param.dateReceived,
					receivedBy: param.receivedBy,
					source: {
						company: param['sourceCompany'],
						storageLocation: param['sourceStorageLocation'],
					},
					destination: {
						company: param['destinationCompany'],
						storageLocation: param['destinationStorageLocation'],
					},
					status: param.status,
					transportation: {
						plateNo: param['plateNo'],
						company: param['transportationCompany'],
					},
					driver: {
						name: param['driverName'],
						company: param['driverCompany'],
						assistants: param['assistants'],
					},
					assets: [],
					accountNo: param['accountNo']
				}
			} else {
				return {
					dispatchId: 'DS' + currTimeStamp,
					...param
				}
			}
		},

		arePropertiesEqual(obj1, obj2, properties) {
			for (const property of properties) {
				if (!obj1 && !obj2) {
					return true;
				} else if (!obj1 || !obj2) {
					return false;
				} else if (obj1[property] !== obj2[property]) {
					return false;
				}
			}
			return true;
		},
		isSameDispatch(newJson, prevJson, extension) {
			let propertiesToCompare;
			if (extension === 'csv') {
				propertiesToCompare = [
					'sourceCompany',
					'sourceStorageLocation',
					'destinationCompany',
					'destinationStorageLocation',
					'accountNo',
					'notes',
					'dateDeployed',
					'deployedBy',
					'dateReceived',
					'receivedBy',
					'status',
					'driverName',
					'driverCompany',
					'assistants',
					'plateNo',
					'transportationCompany',
				];
			} else {
				propertiesToCompare = [
					'source.company',
					'source.storageLocation',
					'destination.company',
					'destination.storageLocation',
					'accountNo',
					'notes',
					'dateDeployed',
					'deployedBy',
					'dateReceived',
					'receivedBy',
					'status',
					'driver.name',
					'driver.company',
					'driver.assistants',
					'transportation.plateNo',
					'transportation.company',
				];
			}
			return this.arePropertiesEqual(newJson, prevJson, propertiesToCompare);
		},
		getParam() {
			return {
				companiesObj: this.companiesObj,
				storageLocationsObj: this.storageLocationsObj,
				connectionsObj: this.connectionsObj,
				assetTypesObj: this.assetTypesObj,
				usersObj: this.usersObj,
				transportationsObj: this.transportationsObj,
				clientAccountsObj: this.clientAccountsObj,
			};
		},

		async onImportData() {
			try {
				let dispatchesArr = this.jsonData.dispatches;
				if (dispatchesArr.length === 0) {
					this.$toaster.warning('At least 1 dispatch is required per import.');
					return;
				} else if (dispatchesArr.length > 10) {
					this.$toaster.warning('Only maximum of 10 dispatches is allowed per import.');
					return;
				} else if (ImportUtil.hasBlankColumnName(dispatchesArr[0])) {
					this.$toaster.warning('Empty Column Name is not allowed. Please check your column names.');
					return;
				} else if (!this.validateDataImport(dispatchesArr)) {
					return;
				}

				await this.saveOnDatabase();
			} catch (error) {
				this.$toaster.warning('The imported dispatch data is invalid and cannot be processed');
			}

			// hide loading indicator
			this.isLoading = false;
		},
		validateDataImport(dispatches) {
			let isValid = true;
			let errors = [];

			let param = this.getParam();

			_.forEach(dispatches, dispatch => {
				errors = [...errors, ...DispatchUtil.validate(dispatch, param)];
			});

			if (errors.length > 0) {
				isValid = false;

				this.hasError = true;
				this.importErrors = errors;
				this.importResultLog = 'The imported form has error(s).';
				this.importDone = true;
				this.importOngoing = false;
			}

			return isValid;
		},

		buildDispatches(dispatches) {
			// reset
			this.dispatches = [];

			for (const json of dispatches) {
				let dispatch = {};
				this.updatePrimaryDetails(dispatch, json);
				this.updateClientAccountDetails(dispatch, json);
				this.updateAssetRelatedFields(dispatch, json);
				this.updateBooleanStateFields(dispatch, json);
				this.updateOtherDispatchFields(dispatch, json, this.getParam());
				this.updateTimestamps(dispatch, json);
				this.dispatches.push(dispatch);
			}

			return this.dispatches;
		},
		updatePrimaryDetails(dispatch, json) {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			let displayTimeStamp = DateUtil.getFormattedDateWithTime(currTimeStamp);

			dispatch.dispatchId = json.dispatchId
				? json.dispatchId
				: 'DS' + currTimeStamp;

			dispatch.status = json.status;
			dispatch.source = this.getDispatchSource(json);
			dispatch.destination = this.getDispatchDestination(json);
			dispatch.sourceScanners = [];
			dispatch.destinationScanners = [];

			dispatch.notes = json.notes ? json.notes + '; ' : '';
			dispatch.notes += 'Created from Dispatch Importer at ' + displayTimeStamp;

			dispatch.transportation = this.getTransportation(json);
			dispatch.driver = this.getDriver(json);
		},
		getDispatchSource(json) {
			let company = json.source.company;
			let companyObj = this.companiesObj[company];

			let storageLocation = json.source.storageLocation;
			let locObj = this.storageLocationsObj[storageLocation];

			return {
				company: companyObj ? companyObj.name : null,
				companyId: companyObj ? companyObj.id : null,
				storageLocation: locObj ? locObj.name : null,
				storageLocationId: locObj ? locObj.id : null,
				geoaddress: locObj ? locObj.geoaddress : null,
			};
		},
		getDispatchDestination(json) {
			let company = json.destination.company;
			let companyObj = this.companiesObj[company];

			let storageLocation = json.destination.storageLocation;
			let locObj = this.storageLocationsObj[storageLocation];

			return {
				company: companyObj ? companyObj.name : null,
				companyId: companyObj ? companyObj.id : null,
				storageLocation: locObj ? locObj.name : null,
				storageLocationId: locObj ? locObj.id : null,
				geoaddress: locObj ? locObj.geoaddress : null,
			};
		},
		getTransportation(json) {
			let company = json.transportation.company;
			let companyObj = this.companiesObj[company];
			return {
				plateNo: json.transportation.plateNo,
				company: companyObj ? companyObj.name : null,
				companyId: companyObj ? companyObj.id : null
			};
		},
		getDriver(json) {
			let company = json.driver.company;
			let companyObj = this.companiesObj[company];
			return {
				name: json.driver.name,
				company: companyObj ? companyObj.name : null,
				assistants: json.driver.assistants,
				companyId: companyObj ? companyObj.id : null
			}
		},

		updateClientAccountDetails(dispatch, json) {
			let clientAccountNo = json.accountNo && json.accountNo.length > 0 ? json.accountNo : '';

			if (clientAccountNo.length > 0) {
				let clientAccountObj = this.clientAccountsObj[clientAccountNo];
				dispatch.clientAccountId = clientAccountObj ? clientAccountObj.id : "";
				dispatch.clientAccountNo = clientAccountObj ? clientAccountObj.accountNo : "";
			} else {
				dispatch.clientAccountId = null;
				dispatch.clientAccountNo = null;
			}
		},

		updateAssetRelatedFields(dispatch, json) {
			dispatch.assets = [];
			for (const asset of json.assets) {
				let assetTypeObj = this.assetTypesObj[asset.assetType];
				dispatch.assets.push({
					assetType: assetTypeObj ? assetTypeObj.name : null,
					assetTypeId: assetTypeObj ? assetTypeObj.id : null,
					expectedQuantity: asset.expectedQuantity ? parseInt(asset.expectedQuantity) : 0,
					actualQuantity: asset.actualQuantity ? parseInt(asset.actualQuantity) : 0
				});
			}
		},
		updateBooleanStateFields(dispatch, json) {
			dispatch.isOffline = false;

			let sourceObj = this.companiesObj[json.source.company];
			if (sourceObj) {
				dispatch.fromInactiveNode = DispatchUtil.isInactiveCompany(sourceObj);
			}

			let destinationObj = this.companiesObj[json.destination.company];
			if (destinationObj) {
				dispatch.toInactiveNode = DispatchUtil.isInactiveCompany(destinationObj);
			}
		},
		updateOtherDispatchFields(dispatch, json, param) {
			dispatch.proofOfReceipt = {};
			if (dispatch.status === 'Received') {
				dispatch.proofOfReceipt.images = null;
				dispatch.proofOfReceipt.remarks = 'Created from Dispatch Importer at ' + DateUtil.getCurrentTimestamp();
				dispatch.proofOfReceipt.recipientName = UserUtil.getUserDisplay(param.usersObj, json.receivedBy);
			}

			dispatch.creationSource = '';
			dispatch.operation = '';
			dispatch.newTransportationToAdd = this.getNewTransportationToAdd(json);
			dispatch.validationWarnings = [];
		},
		getNewTransportationToAdd(json) {
			let newTransportationToAdd = null;

			// Build Transportation Form if still not existing
			if (!this.transportationsObj[json.transportation.plateNo]) {
				let company = json.transportation.company;
				newTransportationToAdd = {
					plateNo: json.transportation.plateNo,
					description: '',
					company: company,
					companyId: '',
					isActive: 'true',
					createdBy: this.currUserId,
					dateCreated: DateUtil.getCurrentTimestamp(),
					updatedBy: this.currUserId,
					dateUpdated: DateUtil.getCurrentTimestamp(),
				};

				let companyObj = this.companiesObj[company];
				if (companyObj) {
					newTransportationToAdd.companyId = this.companiesObj[company].id;
				}
			}

			return newTransportationToAdd;
		},
		updateTimestamps(dispatch, json) {
			let dateDeployed = DateUtil.getTimestamp(json.dateDeployed);

			dispatch.dateCreated = dateDeployed;
			dispatch.createdBy = this.currUserId;

			dispatch.dateUpdated = dateDeployed;
			dispatch.updatedBy = this.currUserId;

			dispatch.dateDeployed = dateDeployed;
			dispatch.deployedBy = json.deployedBy;

			dispatch.dateCancelled = null;
			dispatch.cancelledBy = '';

			if (dispatch.status === 'Received') {
				let dateReceived = DateUtil.getTimestamp(json.dateReceived);
				dispatch.dateReceived = dateReceived;
				dispatch.receivedBy = json.receivedBy;
			} else {
				dispatch.dateReceived = null;
				dispatch.receivedBy = '';
			}
		},

		async saveOnDatabase() {
			try {
				// show loading indicator
				this.isLoading = true;

				this.importOngoing = true;
				this.importDone = false;

				// Build dispatches
				this.dispatches = this.buildDispatches(this.jsonData.dispatches);

				// Continue with import
				let param = {
					currUserId: this.currUserId,
					currTimeStamp: DateUtil.getCurrentTimestamp(),
					dispatches: this.dispatches,
				};

				let { data } = await dataImporterApi.importDispatches(param);

				this.hasError = !data.isSuccess;
				this.importResultLog = data.message;
				this.importDone = true;
				this.importOngoing = false;

				// update store	
				await this.updateAssetTypes(data.dispatches);
				this.$store.dispatch('updateAllDispatches', data.dispatches);

			} catch (error) {
				this.hasError = true;
				this.importResultLog = error;
				this.importDone = true;
				this.importOngoing = false;
			}

			// hide loading indicator
			this.isLoading = false;
		},
		async updateAssetTypes(dispatches) {
			let assetTypeIds = [];
			_.forEach(dispatches, dispatch => {
				assetTypeIds = [...assetTypeIds, _.map(dispatch.assets, 'assetTypeId')];
			});

			let allAssetTypeIds = _.map(this.assetTypesObj, 'id');
			let nonExistentAssetTypeIds = _.difference(assetTypeIds, allAssetTypeIds);

			if (nonExistentAssetTypeIds && !_.isEmpty(nonExistentAssetTypeIds)) {
				let assetTypesObjResults = await assetTypeDAO.getAssetTypesByIds(nonExistentAssetTypeIds);
				let assetTypesObj = assetTypesObjResults[0];

				this.$store.dispatch('updateAllAssetTypes', assetTypesObj);
				this.assetTypesObj = { ...this.assetTypesObj, ...assetTypesObj };
			}
		},
	},
};
</script>
